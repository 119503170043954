import React from "react";
import {withTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TypeUtils from "../../utils/TypeUtils";
import "./CandidateJob.scss";
import Button from "react-bootstrap/Button";
import SuccessModal from "../common/SuccessModal";
import LocationIcon from "@material-ui/icons/LocationOn";

class CandidateJob extends React.Component {

  state = {
    showResult: false,
  };

  hideResult = () => {
    this.setState({showResult: false});
  };

  showResult = (event) => {
    event.preventDefault();
    this.setState({showResult: true});
  }

  render() {

    const {t, job, index} = this.props;

    return (
      <div className="CandidateJob spaced-children">
        <Row>
          <Col className={"spaced-children"}>
            <Row>
              <Col className="title">
                {index + 1}. {job.title}
              </Col>
            </Row>
            <Row>
              <Col className="company">
                {job.company}
              </Col>
            </Row>
            <Row>
              <Col className="skills">
                {TypeUtils.ensureArray(job.skills).join(" | ")}
              </Col>
            </Row>
          </Col>
          <Col xs={"auto"} className={"spaced-children"}>
            <Row>
              <Col className="action-col" xs={"auto"}>
                <SuccessModal show={this.state.showResult} handleClose={this.hideResult}>
                  <p>Le recruteur a été notifié de votre intérêt!</p>
                </SuccessModal>
                <Button variant={"button"} size="sm" onClick={this.showResult}>Ça m'intéresse!</Button>
              </Col>
            </Row>
            {job.location &&
            <Row>
              <Col className="company" xs={"auto"}>
                <LocationIcon className={"icon"}/>Kiosque: {job.location}
              </Col>
            </Row>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="separator"/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(CandidateJob);
