import React from "react";
import {withTranslation} from "react-i18next";
import QueryJobsForCandidate from "./QueryJobsForCandidate";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import CandidateJob from "./CandidateJob";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class CandidateJobs extends React.Component {

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {

    const {t, resume} = this.props;
    const jobFairId = process.env.REACT_APP_DEFAULT_JOBFAIR_ID;

    return (
      <div className="CandidateJobs spaced-children">
        <QueryJobsForCandidate resumeId={resume._id} nlpMatch={resume.nlpMatch} nlpQuery={resume.query} jobFairId={jobFairId} onLoading={this.showLoading} onError={this.showError} onLoaded={(jobs, refetch) =>
          jobs.map((job, index) =>
            <Row key={index}>
              <Col>
                <CandidateJob key={index} job={job} index={index}/>
              </Col>
            </Row>
            )
        }/>
      </div>
    );
  }
}

export default withTranslation()(CandidateJobs);
