import React from "react";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import withAuthUser from "../session/withAuthUser";
import CandidateResumeUpload from "./CandidateResumeUpload";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CandidateLeftMenu from "./CandidateLeftMenu";
import PrivatePage from "../page/PrivatePage";

class CandidateResumeUpdatePage extends React.Component {

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {

    const email = this.props.authUser.firebase.email;
    const jobfairId = process.env.REACT_APP_DEFAULT_JOBFAIR_ID;

    return (
      <PrivatePage>
        <div className="CandidateResumeUpdatePage">
          <Row>
            <Col xs={2}>
              <CandidateLeftMenu/>
            </Col>
            <Col>
              <CandidateResumeUpload email={email} jobfairId={jobfairId} />
            </Col>
          </Row>
        </div>
      </PrivatePage>
    );
  }
}

export default withAuthUser(CandidateResumeUpdatePage);
