import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryResumesForCandidate extends React.Component {
  static GET_RESUMES_FOR_CANDIDATE = gql`
      query BslJobFairGetResumesByEmail($email: String!) {
          bslJobFairGetResumesByEmail(email: $email) {
              _id
              nlpMatch
              query
          }
      }
  `;

  render() {
    return (
      <Query
        query={QueryResumesForCandidate.GET_RESUMES_FOR_CANDIDATE}
        variables={{ email: this.props.email }}
        fetchPolicy = "no-cache"
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data.bslJobFairGetResumesByEmail, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryResumesForCandidate;
