import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import TypeUtils from "../../utils/TypeUtils";

class QueryJobsForCandidate extends React.Component {
  static GET_JOBS_FOR_CANDIDATE = gql`
      query bslJobFairGetJobsForResume($resumeId: String, $nlpMatch: String!, $nlpQuery: [String]!, $fairId: String) {
        bslJobFairGetJobsForResume(resumeId: $resumeId, nlpMatch: $nlpMatch, nlpQuery: $nlpQuery, fairId: $fairId) {
            company
            skills
            title
            location
        }
      }
  `;

  render() {

    return (
      <Query
        query={QueryJobsForCandidate.GET_JOBS_FOR_CANDIDATE}
        variables={{ resumeId: this.props.resumeId, nlpMatch: this.props.nlpMatch, nlpQuery: TypeUtils.ensureArray(this.props.nlpQuery), fairId: this.props.jobFairId }}
        fetchPolicy = "no-cache"
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data.bslJobFairGetJobsForResume, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryJobsForCandidate;
