import React from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";

class QueryCandidate extends React.Component {
  static GET_CANDIDATE = gql`
    query CandidateByEmail($email: String!) {
      candidateByEmail(email: $email) {
        _id
        name
      }
    }
  `;

  render() {
    return (
      <Query
        query={QueryCandidate.GET_CANDIDATE}
        variables={{ email: this.props.email }}
      >
        {result => {
          if (result.loading) return this.props.onLoading();
          if (result.error) return this.props.onError();
          return this.props.onLoaded(result.data.candidateByEmail, result.refetch);
        }}
      </Query>
    );
  }
}

export default QueryCandidate;
