/**
 * List of translation namespaces to load.
 *
 * Namespaces are used when asking a translation with t(namespace:key).
 *
 * As a convention, define one namespace per directory of source code.
 *
 * Translations must be provided in public/locales/{code}/{namespace}.json, where {code} is the language code and
 * {namespace} refers to a file where all translations pertaining to a directory of source code are grouped.
 */

// Use pre-ES6 syntax because translation analysis script in /scripts will complain it doesn't understand "export" instructions
module.exports = [
  'admin',
  'candidate',
  'common',
  'firebase',
  'jobmanagement',
  'menu',
  'notfound',
  'session',
  'settings'
];
