import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResumeDropbox from "../jobmanagement/ResumeDropbox";
import {withTranslation} from "react-i18next";
import * as ROUTES from "../../constants/routes";
import {withRouter} from "react-router-dom";

class CandidateResumeUpload extends React.Component {

  onUploadSucces = () => {
    if (this.props.refetchResume)
      this.props.refetchResume();
    this.props.history.push(ROUTES.HOME);
  }

  render() {

    const {t, email, jobfairId} = this.props;

    return (
      <div className="CandidateResumeUpload spaced-children">
        <Row>
          <Col>
            <h1>{t("candidate:candidate_upload_resume")}</h1>
          </Col>
        </Row>
        <Row className="drop-row">
          <Col>
            <ResumeDropbox email={email} fairId={jobfairId} onSuccess={this.onUploadSucces}/>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CandidateResumeUpload));
