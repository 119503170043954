import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./CandidateLeftMenu.scss";
import * as ROUTES from "../../constants/routes";

class CandidateLeftMenu extends React.Component {

  onClickResume = () => {
    this.props.history.push(ROUTES.CANDIDATE_UPDATE);
  }

  onClickJobs = () => {
    this.props.history.push(ROUTES.HOME);
  }

  render() {

    const {t} = this.props;

    return (
      <div className="CandidateLeftMenu">
        <Card className="SearchPaneTile" onClick={this.onClickJobs}>
          <Card.Header>
            <Row>
              <Col>
                {t("candidate:candidate_view_jobs")}
              </Col>
            </Row>
          </Card.Header>
        </Card>
        <Card className="SearchPaneTile" onClick={this.onClickResume}>
          <Card.Header>
            <Row>
              <Col>
                {t("candidate:candidate_update_resume")}
              </Col>
            </Row>
          </Card.Header>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CandidateLeftMenu));
