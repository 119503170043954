import React from "react";
import RedirectToReferrer from "../signin/RedirectToReferrer";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import StringUtils from "../../utils/StringUtils";
import Col from "react-bootstrap/Col";
import SpinnerButton from "../loadingspinner/SpinnerButton";
import FormText from "react-bootstrap/FormText";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import withFirebase from "../firebase/withFirebase";
import withAuthUser from "../session/withAuthUser";
import {Trans, withTranslation} from "react-i18next";
import "./SignUpForm.scss";
import Terms from "./TermsModal";
import Firebase from "../firebase/Firebase";

class SignUpForm extends React.Component {

  static CANDIDATE_ADD = gql`
    mutation CandidateAdd(
      $name: String!
      $email: String!
    ) {
      candidateAdd(
        name: $name
        email: $email
      ) {
        _id
      }
    }
  `;

  INITIAL_STATE = {
    name: "",
    email: "",
    password: "",
    showTerms: false,
    error: null,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.state = { ...this.INITIAL_STATE };
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onHideTerms = () => {
    this.setState({ showTerms: false });
  };

  onShowTerms = event => {
    event.preventDefault();
    this.setState({ showTerms: true });
  };

  onSubmit = (event, candidateAdd) => {
    event.preventDefault();

    const t = this.props.t;
    const { email, password, name } = this.state;

    // Disable onAuthStateChanged listener before creating user with Firebase because we don't want the event
    // to be fired before the user is created in the user database as well. When all this is done,
    // re-enable the listener as it was before and launch a user refresh.
    // Re-enabling the listener must be done in the onCompleted prop of the mutation, not after candidateAdd here,
    // because the call to candidateAdd returns before it is actually completed.
    this.props.authUser.disableFirebaseListener();

    // Hide previous error, then perform sign up
    this.setState({ error: null, loading: true }, () => {
      this.props.firebase
        .doCreateUserWithEmailAndPassword(email.trim(), password)
        .then(firebaseUser => {
          this.newFirebaseUser = firebaseUser.user;

          // When creating candidate, use email as returned by Firebase because it lowercases it
          candidateAdd({
            variables: {
              name: name,
              email: firebaseUser.user.email,
            }
          });
          // Don't forget to re-enable firebase listener when candidateAdd is completed (see onCompleted in the Mutation)
        })
        .catch(error => {
          const message = t(Firebase.getTranslationKey(error.code)) + " (code: " + error.code + ")";
          this.setState({ error: message, loading: false });

          this.props.authUser.enableFirebaseListener();
        });
    });
  };

  canSubmit = () => {
    const t = this.props.t;
    let e = "";

    if (this.state.name === "") {
      e = t("session:sign_up_company_required");
    }
    if (this.state.email === "") {
      e = t("session:sign_up_email_required");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      e = t("session:sign_up_invalid_email");
    }
    if (this.state.password === "") {
      e = t("session:sign_up_password_required");
    }
    return e === "";
  };

  /**
   * When mutation for candidate creation is done, force a refresh of the FirebaseProvider component.
   */
  onCandidateAddCompleted = () => {
    this.props.authUser.enableFirebaseListener();
    this.props.authUser.setFirebaseUser(this.newFirebaseUser);
  };

  render() {
    const t = this.props.t;
    const { showTerms, error } = this.state;

    // If already logged, or when rendering after sign up, do not display this page, redirect to HOME
    if (this.props.authUser.isAuthenticated) {
      return <RedirectToReferrer/>;
    }

    // noinspection HtmlUnknownTarget
    return (
      <div className="SignUpForm">
        <Mutation mutation={SignUpForm.CANDIDATE_ADD} onCompleted={this.onCandidateAddCompleted}>
          {(candidateAdd /*{ data }*/) => (
            <Form
              onSubmit={event => {
                this.onSubmit(event, candidateAdd);
              }}
            >
              <Terms show={showTerms} onHide={this.onHideTerms} />
              <FormGroup>
                <FormControl
                  name="name"
                  type="text"
                  value={StringUtils.nullToEmpty(this.state.name)}
                  onChange={this.onChange}
                  placeholder={t("session:sign_up_company_placeholder")}
                  autoComplete="candidate-name"
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  name="email"
                  placeholder={t("session:sign_up_email_placeholder")}
                  value={StringUtils.nullToEmpty(this.state.email)}
                  onChange={this.onChange}
                  type="email"
                  autoComplete="email"
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  name="password"
                  type="password"
                  placeholder={t("session:sign_up_password_placeholder")}
                  value={StringUtils.nullToEmpty(this.state.password)}
                  onChange={this.onChange}
                  autoComplete="current-password"
                />
              </FormGroup>
              <FormGroup>
                <Form.Row className={"agree-row"}>
                  <Col xs="auto">
                    <span className="agree-text">
                      <Trans i18nKey="session:sign_up_agree_msg">
                      You agree to the Mouse At Work{" "}
                        <a href="_blank" onClick={this.onShowTerms}>terms and conditions</a>
                      </Trans>
                    </span>
                  </Col>
                </Form.Row>
              </FormGroup>
              <FormGroup className="last-button-group">
                <SpinnerButton
                  loading={this.state.loading}
                  type="submit"
                  disabled={!this.canSubmit()}
                  className="submit-button"
                >
                  {t("session:sign_up_sign_up_button")}
                </SpinnerButton>
              </FormGroup>
              {!StringUtils.isNullOrEmpty(error) && (
                <FormText as="div" className="form-error">
                  {error}
                </FormText>
              )}
            </Form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withRouter,
  withFirebase,
  withAuthUser
)(SignUpForm);
