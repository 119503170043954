import React from "react";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import LoadingDataError from "../common/LoadingDataError";
import QueryResumesForCandidate from "./QueryResumeForCandidate";
import withAuthUser from "../session/withAuthUser";
import CandidateResumeUpload from "./CandidateResumeUpload";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CandidateLeftMenu from "./CandidateLeftMenu";
import CandidateJobs from "./CandidateJobs";

class CandidateHomeRedirect extends React.Component {

  showLoading = () => {
    return <LoadingSpinner />;
  };

  showError = () => {
    return <LoadingDataError/>;
  };

  render() {

    const email = this.props.authUser.firebase.email;
    const jobfairId = process.env.REACT_APP_DEFAULT_JOBFAIR_ID;

    return (
      <QueryResumesForCandidate email={email} onLoading={this.showLoading} onError={this.showError} onLoaded={(resumes, refetch) => {
        const resume = (!resumes || resumes.length === 0) ? null : resumes[0];

        return (
          <div className="CandidateHomeRedirect">
            <Row>
              {resume &&
              <Col xs={2}>
                <CandidateLeftMenu/>
              </Col>
              }
              <Col>
                {resume &&
                  <CandidateJobs resume={resume}/>
                }
                {!resume &&
                <CandidateResumeUpload email={email} jobfairId={jobfairId} refetchResume={refetch}/>
                }
              </Col>
            </Row>
          </div>
        );
      }}/>
    );
  }
}

export default withAuthUser(CandidateHomeRedirect);
